import {isNotQualified} from '@aoflmkt/is-webp-qualified';

const imageConfig = {
  loginLogo: [
    {maxWidth: 320, src: `${isNotQualified ? '/assets/routes/login/hsplus-logo-mb-v1-1x.png' : '/assets/routes/login/hsplus-logo-mb-v1-1x.webp'}`},
    {maxWidth: 480, src: `${isNotQualified ? '/assets/routes/login/hsplus-logo-mb-v1-2x.png' : '/assets/routes/login/hsplus-logo-mb-v1-2x.webp'}`},
    {maxWidth: 568, src: `${isNotQualified ? '/assets/routes/login/hsplus-logo-mb-v1-3x.png' : '/assets/routes/login/hsplus-logo-mb-v1-3x.webp'}`},
    {maxWidth: 640, src: `${isNotQualified ? '/assets/routes/login/hsplus-logo-dt-v1-1x.png' : '/assets/routes/login/hsplus-logo-dt-v1-1x.webp'}`},
    {maxWidth: 768, src: `${isNotQualified ? '/assets/routes/login/hsplus-logo-dt-v1-2x.png' : '/assets/routes/login/hsplus-logo-dt-v1-2x.webp'}`},
    {maxWidth: 1024, src: `${isNotQualified ? '/assets/routes/login/hsplus-logo-dt-v1-3x.png' : '/assets/routes/login/hsplus-logo-dt-v1-3x.webp'}`}
  ],
};

export {
  imageConfig
};

